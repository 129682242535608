import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SE } from './directives/scroll.directive';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ContactDialogComponent } from './contact-dialog/contact-dialog.component';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit,OnDestroy {


  contactFabButton: any;
  bodyelement: any;
  sidenavelement: any;

  isActive = false;
  isActivefadeInDown = true;
  fixedTolbar = true;


  stateModalSup = true;

	mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  dialogRef: MatDialogRef<any>;

  mostrarPopup: boolean = true;

  constructor(@Inject(DOCUMENT) document, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public dialog: MatDialog, private dialogModal: MatDialog) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnInit(): void {
   
      // this.openDialogModal();
      document.body.style.overflow = 'hidden';
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  cerrarPopup() {
    this.mostrarPopup = false;
    document.body.style.overflow = ''; // Restaura el desplazamiento de la página
  }

  public detectScroll(event: SE) {
    if (event.header) {
      this.isActive = false;
      this.isActivefadeInDown = true;
      this.fixedTolbar = true;
    }

    if (event.bottom) {
      this.isActive = true;
      this.isActivefadeInDown  = false;
      this.fixedTolbar = false;
    }
  }

  closeModalSup() {
    this.stateModalSup = false;
  }

  openContact(): void {
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      width: '250px'
    });
  }

  openDialog(): void {
    window.location.href = environment.portalurl;
  }

  openRegister(): void {
    window.location.href = `${ environment.portalurl }/#/register`;
  }

  openBlog(): void {
    let blog = $('#blog');
    let rside =$('.rightsidepanel');
      rside.css('display', 'none');
      blog.css('display', 'block');
  }

  setToggleOn(){
    this.bodyelement = document.getElementById('nglpage');
    this.bodyelement.classList.add("scrollOff");
    this.contactFabButton = document.getElementById('contact-fab-button');
    this.contactFabButton.style.display = "none";
  }

  setToggleOff(){
    this.bodyelement = document.getElementById('nglpage');
    this.bodyelement.classList.remove("scrollOff");
    this.contactFabButton = document.getElementById('contact-fab-button');
    this.contactFabButton.removeAttribute("style");
  }

  

}
