import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class Service {

  server: string = environment.portalapi;
 
  constructor(private http: HttpClient/*, private router: Router*/) { 

  }

  // funciones de sesion
  validateToken(): boolean {
    if (localStorage.getItem('token'))
      return true;
    else
      return false;
  }

  readToken() {
    if (this.validateToken())
      return localStorage.getItem('token');
    else
      return '';
  }

  readKey() {
    return environment.portalkey;
  }

  IsAuthenticathed(): boolean {
    return this.validateToken();
  }

  // funciones de peticiones puras
  Delete(query: string) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${ this.readToken() }`, Accept: 'application/json', 'Content-Type': 'application/json' });

    return this.http.delete(`${ this.server }${ query }`, { headers }).pipe(catchError(this.handleError));     
  }

  Get(query: string, sendHeaders: boolean = true) {
    const URL = `${ this.server }${ query }`;
    const headers = new HttpHeaders({ Authorization: `Bearer ${ this.readToken() }`, 'Content-Type': 'application/json; charset=utf-8' });

    if (sendHeaders)
      return this.http.get(URL, { headers }).pipe(catchError(this.handleError));
    else
      return this.http.get(URL).pipe(catchError(this.handleError));
  }

  Patch(query: string, data: any) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${ this.readToken() }`, Accept: 'application/json', 'Content-Type': 'application/json' });
    
    return this.http.patch(`${ this.server }${ query }`, data, { headers }).pipe(catchError(this.handleError));
  }

  Post(query: string, data: any, sendHeaders: boolean = true) {
    let options: any;

    if (sendHeaders)
      options = { Authorization: `Bearer ${ this.readToken() }`, Accept: 'application/json', 'Content-Type': 'application/json' };
    else
      options = { Authorization: ``, Accept: 'application/json', 'Content-Type': 'application/json' };

    const headers = new HttpHeaders(options);

    return this.http.post(`${ this.server }${ query }`, data, { headers }).pipe(catchError(this.handleError));
  }

  PostKey(query: string, data: any) {
    let options: any = { 'x-api-key': `${ this.readKey() }`, Accept: 'application/json', 'Content-Type': 'application/json' };
    const headers = new HttpHeaders(options);

    return this.http.post(`${ this.server }${ query }`, data, { headers }).pipe(catchError(this.handleError));
  }

  PostFile(query: string, data: any) {
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.readToken()}`, });
    
    return this.http.post(`${ this.server }${ query }`, data, { headers }).pipe(catchError(this.handleError));
  }

  Put(query: string, data: any) {
    const URL = this.server + query;
    const headers = new HttpHeaders({ Authorization: `Bearer ${ this.readToken() }`, Accept: 'application/json', 'Content-Type': 'application/json' });
    
    return this.http.put(URL, data, { headers }).pipe(catchError(this.handleError));     
  }

  // Funcion para el Manejo de errores
  handleError(err: HttpErrorResponse): any {
    let errorMessage = '';
    debugger
    if (err.error instanceof ErrorEvent)
      errorMessage = ``;
    else {
      switch (err.status) {
        case 401:
          errorMessage = ``;

          localStorage.clear();
          console.log('error regresar login');
            
          //this.router.navigate(['/login']);

          break;
        case 500:
          errorMessage = ``;
          
          break;
        default:
          errorMessage = `${ err.error }`;

          break;
      }
    }

    if (err.status != 401)
      return throwError(errorMessage);
  }

}
