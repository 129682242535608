import { NgModule } from '@angular/core';
import { MatButtonModule, MatCheckboxModule, MatCardModule, MatDividerModule, MatIconModule,
	     MatSidenavModule, MatToolbarModule, MatListModule, MatTooltipModule, MatTabsModule, MatTableModule,
	     MatFormFieldModule, MatInputModule, MatDialogModule, MatExpansionModule } from '@angular/material';

@NgModule({

  imports: [MatButtonModule, MatCheckboxModule, MatCardModule, MatDividerModule, MatIconModule,
            MatSidenavModule, MatToolbarModule, MatListModule, MatTooltipModule, MatTabsModule,
            MatTableModule, MatFormFieldModule, MatInputModule, MatDialogModule, MatExpansionModule],
  exports: [MatButtonModule, MatCheckboxModule, MatCardModule, MatDividerModule, MatIconModule,
            MatSidenavModule, MatToolbarModule, MatListModule, MatTooltipModule, MatTabsModule,
            MatTableModule, MatFormFieldModule, MatInputModule, MatDialogModule, MatExpansionModule]
})
export class Material2Module { }
