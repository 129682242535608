import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormGroupDirective, NgForm, FormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Contact } from '../contact/contact';

import { HttpClient } from '@angular/common/http';
import { Service } from 'src/tools/api.service';

export class NgLpErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.css']
})
export class ContactDialogComponent {

  valueError: string = "";
  private httpClient: HttpClient;

  nameFormControl = new FormControl('', [
    Validators.required
  ]);

  phoneFormControl = new FormControl('', [
    Validators.required
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new NgLpErrorStateMatcher();

  contact = new Contact();

  messageFormControl = new FormControl('', []);

  contacto: boolean = true;
  modal: boolean = false;
  vacio: boolean = false;

  constructor(public _api: Service, http: HttpClient,
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.httpClient = http;
    }

  dialogOff(): void {
    this.dialogRef.close();
  }
  
  onSubmit() {
    this.valueError = "";

    try {
      if (this.nameFormControl.hasError('required') || this.phoneFormControl.hasError('required') || this.emailFormControl.hasError('required'))
        this.vacio=true;
      else
      {
        this.httpClient.get('assets/resource/contactus.txt', { responseType: 'text' }).subscribe(txt => {
        var html = txt;
        html = html.replace("{{nombre}}", this.nameFormControl.value).replace("{{telefono}}", this.phoneFormControl.value).replace("{{correoelectronico}}", this.emailFormControl.value).replace("{{mensaje}}", this.messageFormControl.value);

        let data: any = { 
          enviadopor: "CONTACT US", 
          enviara: "contacto@contigoentodo.com", 
          asunto: `contacto cliente ${ this.nameFormControl.value }`, 
          mensaje: html
        };

        this._api.PostKey(`distribucion/enviarcorreo`, data).subscribe((result: boolean) => {
          if (result) {
            this.contacto = false;
            this.mostar();
            //this.dialogRef.close();
          }
          else {
            this.valueError = "No se pudo procesar el correo de contacto";  
          }
        },
        error => {
          this.valueError = error;
        });
      },
      error => {
        this.valueError = error;
        });
      } 
    }
    catch(e) {
      this.valueError = e;
    }
     
  }

  mostar(){
    if(this.contacto == true)
    {
      this.modal = false;
    }else{
      this.contacto = false;
      this.modal = true;
    }
  }

  cerrar(){
    this.dialogRef.close();
  }

}
