import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {


  constructor() { }
   count = 0;
   idol = null;

   show = null;

  openRec(e, id): void {



    id = '#'+id;
    if(this.idol == id){
      this.count ++;
    }
    else{
      this.count= 1;
    }


    console.log(this.count);
    let blog = $(id);
    let button =  '#btn_'+id;


      if (this.count % 2 == 0) {
        blog.css('overflow', 'hidden');
        blog.css('height', '36px');
        e.textContent ="leer más";

        }
        else{
          blog.css('overflow', 'auto');
          blog.css('height', 'auto');
          e.textContent ="cerrar";

        }
        this.idol = id;
        console.log(this.idol);
      }


  ngOnInit() {


  }

}
