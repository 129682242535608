export const environment = {
  production: false,
  portalurl: "https://portal.contigoentodo.com/",
  portalsite: "http://localhost:4200",
  portalapi: 'https://portal.api.contigoentodo.com/api/',
  portalkey: 'OenWjoWOL4jwbw705r7oEux4kmcc7m3SrT6RCos7lDA99zUvBER7cVRf1OFnXVZYUvIu8JBitZ4oCpFCl9SmVVlVI9lTS6VN6UCgpVQ9Bn1Va8ILz2v7qF4jYf6zzbLC',
  recaptcha: {
    siteKey: '6Ldo8lQdAAAAAMx81IbhyxTVkJh9FHea9NT2yVOF',
  }
};
