import { Component, ViewChild, OnInit } from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';

import { HttpClient } from '@angular/common/http';
import { Service } from 'src/tools/api.service';

import * as $ from "jquery";

@Component({
  selector: 'app-reclamacion',
  templateUrl: './reclamacion.component.html',
  styleUrls: ['./reclamacion.component.css']
})
export class ReclamacionComponent implements OnInit {

  valueError: string = "";
  private httpClient: HttpClient;

  nombre: string = "";
  apellido: string = "";
  correoelectronico: string = "";
  tipodocumento: string = "";
  numerodocumento: string = "";
  telefono: string = "";
  departamento: string = "";
  provincia: string = "";
  distrito: string = "";
  direccion: string = "";
  esmenoredad: string = "";
  isMenorEdad: boolean = false;
  nombretutor: string = "";
  correoelectronicotutor: string = "";
  documentotutor: string = "";
  direcciontutor: string = "";
  ordencompra: string = "";
  montoreclamado: string = "";
  montoreclamo: string = "";
  descripcionproducto: string = "";
  detalle: string = "";
  pedidoreclamado: string = "";
  detalleproveedor: string = "";

  reclamo: boolean = true;
  modal: boolean = false;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(public _api: Service, http: HttpClient) {
    this.httpClient = http;
  }

  ngOnInit() {
 	  jQuery(function($) {
      let blog = $('#rec');

      $('.close_btn').click(function() {
        blog.css('display', 'none');
      });
	  });
  }
  
  onSubmit() {
    this.valueError = "";
    
    try {
      this.httpClient.get('assets/resource/libroreclamacion.txt', { responseType: 'text' }).subscribe(txt => {
        var html = txt;
        html = html.replace("{{nombre}}", this.nombre)
                   .replace("{{apellido}}", this.apellido)
                   .replace("{{correoelectronico}}", this.correoelectronico)
                   .replace("{{tipodocumento}}", this.tipodocumento)
                   .replace("{{numerodocumento}}", this.numerodocumento)
                   .replace("{{telefono}}", this.telefono)
                   .replace("{{departamento}}", this.departamento)
                   .replace("{{provincia}}", this.provincia)
                   .replace("{{distrito}}", this.distrito)
                   .replace("{{direccion}}", this.direccion)
                   .replace("{{esmenoredad}}", this.esmenoredad)
                   .replace("{{nombretutor}}", this.nombretutor)
                   .replace("{{correoelectronicotutor}}", this.correoelectronicotutor)
                   .replace("{{documentotutor}}", this.documentotutor)
                   .replace("{{direcciontutor}}", this.direcciontutor)
                   .replace("{{ordencompra}}", this.ordencompra)
                   .replace("{{montoreclamado}}", this.montoreclamado)
                   .replace("{{montoreclamo}}", this.montoreclamo)
                   .replace("{{descripcionproducto}}", this.descripcionproducto)
                   .replace("{{detalle}}", this.detalle)
                   .replace("{{pedidoreclamado}}", this.pedidoreclamado)
                   .replace("{{detalleproveedor}}", this.detalleproveedor);

        let data: any = { 
          enviadopor: "LIBRO DE RECLAMACION", 
          enviara: "contacto@contigoentodo.com", 
          asunto: `Reclamo cliente ${ this.nombre } ${ this.apellido }`, 
          mensaje: html
        };

        this._api.PostKey(`distribucion/enviarcorreo`, data).subscribe((result: boolean) => {
          if (result) {
            this.valueError = "";
            this.reclamo = false;
            this.mostar();

          }
          else {
            this.valueError = "No se pudo procesar el correo de libro de reclamacion";
          }
        },
        error => {
          this.valueError = error;
        });
      },
      error => {
        this.valueError = error;
      });
    }
    catch(e) {
      this.valueError = e;
    }
  }

  menoredad(){
    if(this.esmenoredad == "Si")
      this.isMenorEdad = true;
    else
      this.isMenorEdad = false;  
  }

  mostar(){
    if(this.reclamo == true)
    {
      this.modal = false;
    }else{
      this.reclamo = false;
      this.modal = true;
    }
  }

  cerrar(){
    let blog = $('#rec');
    blog.css('display', 'none');
  }
}
