import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, NgForm, FormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { environment } from 'src/environments/environment';
import { Contact } from './contact';

/** Error when invalid control is dirty, touched, or submitted. */
export class NgLpErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  _site: string = "";

  nameFormControl = new FormControl('', [
    Validators.required
  ]);

  phoneFormControl = new FormControl('', [
    Validators.required
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new NgLpErrorStateMatcher();

  contact = new Contact();

  constructor() { }

  openRec(): void {
    let blog = $('#rec');
      blog.css('display', 'block');
  }

  ngOnInit() {
    this._site = environment.portalsite;
  }

  onSubmit() {
  	console.log("FORM SEND");
  }

  openBlog(): void {
    let blog = $('#blog');
    blog.css('display', 'block');
  }

}
