import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Service } from 'src/tools/api.service';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.css']
})
export class HeadingComponent implements OnInit {

  isHeading = true;
  isSubheading = true;
  isHeadingBtn = true;

  valueError: string = "";
  monto: number = 400;
  cuota: number = 4;
  promedio: number = 0;

  token: string|undefined;

  _site: string = "";

  constructor(public _api: Service) { 

  }

  ngOnInit() {    
    this.Promedio();

    this._site = environment.portalsite;
  }

  changeToStep2() {
    window.location.href = environment.portalurl;
  }

  onChangePromedio(event: any) {
    /*if (typeof this.token === "undefined")
      this.valueError = 'debe marcar el catpcha';
    else*/
      console.log(this.cuota);
      console.log(this.monto);
      this.Promedio();
  }

  Promedio() {
    this._api.PostKey(`distribucion/simulacion`, { id: "e2de7e9d-7b4d-48e5-a1a1-415ac6f4bac7", numerodocumento: "00000000", cuota: this.cuota, monto: this.monto }).subscribe((result: number) => {
      this.promedio = result;
      this.valueError = result.toString();
      console.log(this.promedio);
    },
    error => {
      this.valueError = error;
    });
  }










}
